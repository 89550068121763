.wrapper {
  font-family: "cyberfallregular";
  color: #fafafa;
  opacity: 0.8;
  padding: 0;
  margin: 0;
  text-align: center;
}

.normal {
  font-size: 32px;
}

.large {
  font-size: 56px;
}
