:root {
  --base-color: rgba(255, 255, 255, 0.8);
  --hover-color: rgba(0, 0, 0, 1);
}

.button {
  background: none;
  position: relative;
  padding: 12px;
  border: 2px solid var(--base-color);
  font-family: 'motion_controlbold';
  font-size: 24px;
  cursor: pointer;
}

.button:focus {
  outline: none;
}

.disabled {
  opacity: 0.5;
}

.button > .text {
  position: relative;
  color: transparent;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(var(--hover-color)),
    color-stop(50%, var(--hover-color)),
    color-stop(50%, var(--base-color)),
    to(var(--base-color))
  );
  background-image: linear-gradient(
    90deg,
    var(--hover-color) 0%,
    var(--hover-color) 50%,
    var(--base-color) 50%,
    var(--base-color) 100%
  );
  background-repeat: repeat;
  background-size: 200%;
  background-position: 100% 0;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-transition: background-position 300ms;
  transition: background-position 300ms;
}
.button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--base-color);
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0;
  -webkit-transform: scale3d(0, 1, 1);
  transform: scale3d(0, 1, 1);
  -webkit-transition: -webkit-transform 300ms;
  transition: -webkit-transform 300ms;
  transition: transform 300ms;
  transition: transform 300ms, -webkit-transform 300ms;
}
.button:hover .text {
  background-position: 0 0;
}
.button:not(.disabled)::before {
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}

.icon {
  position: absolute;
  top: 50%;
  left: 6px;
  transform: translateY(-50%);
  color: white;
}

.icon svg {
  fill: var(--base-color);
}

.button:hover .icon svg {
  fill: var(--hover-color);
}

.hasIcon {
  padding-left: 24px;
}
