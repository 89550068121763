html,
body {
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  background-color: #090909;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'digital';
  src: url('./assets/font/digital-7_mono-webfont.woff2') format('woff2'),
    url('./assets/font/digital-7_mono-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'motion_controlbold';
  src: url('./assets/font/motioncontrol-bold-webfont.woff2') format('woff2'),
    url('./assets/font/motioncontrol-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'cyberfallregular';
  src: url('./assets/font/cyberfall-webfont.woff2') format('woff2'),
    url('./assets/font/cyberfall-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

p {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 1.3;
}

p.text {
  color: rgba(255, 255, 255, 0.8);
  max-width: 500px;
  text-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  padding: 2px;
  background-color: lightgray;
  /* border: 1px solid darkgray; */
  color: black;
  border-radius: 4px;
  font-size: 12px;
  white-space: pre;
}

h3 {
  font-family: 'motion_controlbold';
  font-size: 24px;
}

p.error {
  color: red;
  white-space: pre;
}

p.expected {
  color: green;
  margin-top: 0;
  margin-bottom: 0;
}
p.gotten {
  color: red;
  margin-top: 0;
}

p.result {
  font-weight: bold;
  margin-bottom: 2px;
}

p.line {
  white-space: pre-wrap;
}

* {
  box-sizing: border-box;
}

#root {
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
