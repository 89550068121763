.gameContainer {
  width: 140vh;
  min-width: 800px;
}

.aspectRatioContainer {
  position: relative;
  border-radius: 8px;
  width: 100%;
  padding-top: 63.8%;
  background-image: url('/assets/pattern.jpg');
  background-size: 15%;
  background-repeat: repeat;
  box-shadow: inset 0px 0px 16px 0px rgba(0, 0, 0, 0.75);
}

.contentWrapper {
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  border-radius: 5px;
  overflow: hidden;
  background-color: black;
  /* box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.75); */
}

.image {
  position: absolute;
  top: 8px;
  left: 16px;
  z-index: 3;
}

.sceneContainer {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.timeContainer {
  position: absolute;
  top: 0;
  right: 0;
}
