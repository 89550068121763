.wrapper {
  position: absolute;
  bottom: 10px;
  right: 12px;
  z-index: 3;
}

.wrapper a {
  color: #8e8e8e;
  text-decoration: none;
}

.wrapper a svg {
  fill: #fafafa;
  width: 20px;
}

.wrapper a:hover {
  color: white;
  text-decoration: underline;
}

body:fullscreen .wrapper a {
  display: none;
}
