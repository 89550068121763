.wrapper {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 10px;
  width: 100%;
  text-align: center;
}

.wrapper * {
  margin-right: 6px;
}

.wrapper *:last-child {
  margin-right: 0;
}

.wrapper a {
  color: #8e8e8e;
  text-decoration: none;
}

.wrapper a:hover {
  color: white;
  text-decoration: underline;
}

.wrapper p {
  color: #8e8e8e;
  font-size: 10px;
}
