.wrapper {
    background-color: black;
    color: white;
    font-size: 32px;
    border: 4px solid rgba(255, 255, 255, 0.8);
    font-family: "motion_controlbold";
    text-align: center;
    padding: 5px 10px;
}

.wrapper:focus {
    outline: none;
}
