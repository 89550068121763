.wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 24px 100px;
    z-index: 99;
    text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.75);
    text-align: center;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 24px;
}
