.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button {
  margin-top: 12px;
}

.text {
  margin-bottom: 32px;
}

.link {
  color: white;
  margin-top: 20px;
  font-size: 13px;
}

.form {
  display: flex;
  flex-direction: row;
}

.form * {
  margin-right: 6px;
  margin-bottom: 18px;
}

.form *:last-child {
  margin-right: 0;
}
