.run {
  padding: 12px;
  background-color: #222222;
  display: flex;
  justify-content: flex-end;
}

.run * {
  margin-right: 6px;
}

.run *:last-child {
  margin-right: 0;
}

.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: calc(100% - 50px);
}

.leftSide {
  width: 55%;
}

.rightSide {
  width: 45%;
  display: flex;
  flex-direction: column;
}

.label {
  position: absolute;
  top: 6px;
  left: 6px;
  margin: 0;
}

.title {
  height: 50px;
}

.description {
  position: relative;
  background-color: #222222;
  color: white;
  padding: 0 6px;
  overflow: auto;
  padding-top: 30px;
  flex: 1;
}

.description p {
  line-height: 1.5;
  white-space: pre-wrap;
}

.output {
  position: relative;
  background-color: #1d1d1d;
  color: white;
  padding: 0 6px;
  flex: 1;
  overflow: auto;
  padding-top: 30px;
}

.editor {
  height: 100%;
}

.red {
  color: red;
}

.gray {
  color: gray;
}

.bold {
  font-weight: bold;
}

.bgRed {
  background-color: darkred;
}

.yellow {
  color: yellow;
}

.green {
  color: greenyellow;
}

.blue {
  color: aqua;
}
