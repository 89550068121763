.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.image {
  position: absolute;
  top: 8px;
  right: 16px;
}

.title {
  margin-bottom: 20px;
}

.logo {
  width: 400px;
}

.text {
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 32px;
  max-width: 500px;
  text-align: center;
}
