.wrapper {
  display: flex;
  flex-direction: row;
}

.wrapper * {
  margin-right: 6px;
}

.wrapper *:last-child {
  margin-right: 0;
}

.button {
  background: none;
  outline: 0;
  padding: 0;
  border: 0;
  cursor: pointer;
  transition: all 0.1s ease-out;
}

.button:hover {
  transform: scale(1.1);
}

.button svg {
  width: 32px;
  height: 32px;
  fill: white;
}
