.wrapper {
  width: 100%;
  overflow: hidden;
  height: 0;
  transition: all 0.3s ease-in-out;
  background-color: rgba(0, 0, 0, 0.6);
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 24px;
  width: 100%;
  height: 100%;
}

.normal {
  height: 250px;
}

.large {
  height: 90%;
}
